.front{
  /*CAROUSEL*/
  .slide-text p a {
    color: $wcm-dark-orange;
  }

  /*WELCOME AND NEWS*/
  .welcome-news{
    background-color: #eee;
    padding: 0px;
    height: auto;
  }

  /*WELCOME*/
  .homepage-welcome{
    padding: 30px 30px 35px 5.5%;
    border-right: solid 20px $wcm-white;
    @include clearfix;
    @media (max-width : 991px){
      border-right: 0px;
      border-bottom: solid 5px $wcm-white;
    }
    
    .panopoly-image-original{
      padding: 0px !important;
      width: 300px !important;
      img{
        border: solid 10px $wcm-white;
        border-radius: 5px;
        box-shadow: 5px 5px 5px $wcm-border-gray;    
      }
    }

    blockquote{
      border-left: none;
      font-size: 22px;
      padding: 0px;
      p{
        line-height: 35px;
      }
    }

    .banner-icon__testimonial_invert{
      webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
      moz-transform: rotate(180deg);        /* FF */
      o-transform: rotate(180deg);          /* Opera */
      ms-transform: rotate(180deg);         /* IE9 */
      transform: rotate(180deg);             /* W3C compliant browsers */
      @include clearfix;
    }

    div.caption-left {
      float: left;
      margin: 20px 40px 20px 0;
    }
  }

  /*NEWS*/
  .homepage-news{
    padding: 30px 5.5% 30px 30px;
    @include clearfix;

    img{
      max-width: 200px;
      float: left;
      padding: 0px 10px 10px 0px;
    }
    
    .date{
      font-size: 13px;
      padding: 10px 0px;
      display: block;
    }

    .read-more{
      float: right;
      display: block;
      padding: 10px 0px;
      @include clearfix;
    }
  }  

  /*CTA*/
  .homepage-cta{
    text-align: center;
    .hompeage-cta-icons{
      list-style: none;
      display: inline-block;
      margin: 10px 0px;
      padding: 0px;
      @include clearfix;
        li{
          width: 200px;
          height: 120px;
          float: left;
          padding: 15px 0px;
          margin: 40px;
          display: inline-block;
          @media (max-width : 1024px){
            float: none;
            margin: 20px;
          }
        }
        li:hover{
          box-shadow: 1px 2px 2px 2px #ccc;
          border-radius: 5px;
        }
        a:hover{
          text-decoration: none !important;
        }
      }
    }    

  /*LOCATION*/
  .homepage-location{ 
    background-color: #eee;
    padding: 3% 3% 0% 3%; 
    p{
    padding: 0px 200px 15px 200px;
    @media (max-width : 991px){
      padding: 10px;
      }
    }
  }

  .maps-text{
    background-color: $wcm-border-gray;
    padding: 5px;
    margin: 5px 0px;
  }

  /*DISCLAIMER*/
  .disclaimer{
    size: 13px; 
  }
  .disclaimer a{
    color: $wcm-med-gray;
  }

  .disclaimer a:hover{
    color: $wcm-bright-orange;
  }

}