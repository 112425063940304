/*NAV OVERRIDE*/
/*Level 1 Nav*/



#primary-nav .level-1 {
    @include breakpoint($sm){
        width: 16%;
        }   

    @include breakpoint($md){
        width: 16%;
        }

    @include breakpoint($lg){
        width: auto;
        padding: 8px 24px !important;
        }
    }

#drawer-nav {
  ul.menu {
    li.level-1:nth-child(1) {
      ul.menu {
        li.level-2{
          width: 20%;
          padding: 8px 10px;
        }
      }
    }
    li.level-1:nth-child(2) {
      ul.menu {
        li.level-2 {
          width: 25%;
          padding: 8px 29px;
        }
      }
    }
    li.level-1:nth-child(3) {
      ul.menu {
        li.level-2 {
          width: 22%;
          padding: 8px 20px;
        }
      }
    }
    li.level-1:nth-child(6) {
      ul.menu {
        li.level-2 {
          width: auto;
          padding: 8px 15px !important;
        }
      }
    }
  }
}



/*Footer Nav*/
.block-menu-block .content {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
    }