/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

/*HEADSHOT STYLING*/
.headshot-details {
    width: 150px;
}

.headshot {
    width: 150px;
}

.panopoly-image-original{
	padding: 40px 30px;
}

/*REVEAL STYLING*/
.ctools-collapsible-container {
   .ctools-toggle {
       display: none;
   }
}
.ctools-collapsible-processed {
   .ctools-collapsible-handle h2 {
       position: relative;
       font-size: 18px;
       line-height: 20px;
       color: $wcm-dark-orange;
       border: 1px solid $wcm-border-gray;
       border-width: 3px 0 0;
       cursor: pointer;
       margin: 0;
       padding: 15px 55px 15px 0px;
       font-weight: 600;

       &:hover {
           color: $wcm-bright-orange;
       }

       &:after {
           content: '-';
           width: 50px;
           display: block;
           position: absolute;
           top: 15px;
           right: 0;
           font-size: 30px;
           font-weight: 600;
           text-align: center;
           border-left: 3px solid $wcm-border-gray;
       }
   }
   
   &.ctools-collapsed .ctools-collapsible-handle h2:after {
       content: '+';
   }
}

/*PROFILES*/

.node-type-profile{

  /*PROFILE IMAGES*/
  .profile-image {
    @include breakpoint($xs){
      float: none;
    }
    @include breakpoint($sm){
      float: left;
      padding: 0px 20px 0px 0px;    
    }
    @include breakpoint($md){
      float: left;
      padding: 0px 20px 0px 0px;    
    }
    @include breakpoint($lg){
      float: left;
      padding: 0px 20px 0px 0px;
    }
  }

  /*PROFILE CENTER COLUMN LIST STYLING*/

  .menu .level-3{
    list-style: none;
    margin: 0 0 10px -10px;
  }

  .item-list ul li {
    margin: 0 0 10px 0px !important;
    padding: 0px;
    list-style-type: none;
  }

  .field-name-field-insurance-accepted, .field-name-field-clinical-expertise, .field-name-field-office-location{
    margin-left: -20px !important;
    @media (max-width : 768px){
        margin-left: 0px !important;
      }
  }

  #related-content-sidebar ul {
      padding-left: 10px;
  }

  #related-content-sidebar .panel-pane, #related-content-inline .panel-pane {
    margin-bottom: 0px;
}

  .pane-node-field-professional-title{
      font-weight: bold;
  }

  .pane-views-panes .textformatter-list{
      padding-left: 110px;
      text-indent: 0px;
  }

}

/*EXPOSED FILTER FORMATTING
  .views-exposed-form .views-exposed-widget {
      float: left;
      padding: .5em 1em 30px 0;
  } */

  /*PROFILE LIST VIEW IMAGE*/
  .wcmc-profile-table{
      float: left;
      margin: 5px 10px 5px 0px;
  }

  .view-profiles{
    ul{
      list-style: none;
      li{
        margin: 0px 0px 10px 110px;
        }
      }
    }

   
/*DROP DOWN FORMATTING*/
.select2-container{
    width: 300px !important;
  }

.hr{
  border-bottom: 1px solid $wcm-black;
}

.views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 2.3em;
}

/*CTA*/
.node-type-profile{
  .information-column{
    @include breakpoint($xs){
      clear: both;
    }
    @include breakpoint($sm){
      clear: both;
    }
    @include breakpoint($md){
      clear: none;
    }
    @include breakpoint($lg){
      clear: none;
    }
  }
}
